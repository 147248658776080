import "./App.css";
import "amazon-connect-streams";
import React from "react";
import {
  CallMade,
  CallReceived,
  Phone,
  ContentCopy,
} from "@mui/icons-material";

const ccpURL = "https://benmanage.my.connect.aws/ccp-v2/#";
const DEBUG_MODE = false;
const DIRECTIONS = { INBOUND: "Inbound", OUTBOUND: "Outbound" };

const DEF_CLIENT_NAME = "(none)";

function App() {
  const savedCallLogText = window.localStorage.getItem("callLog");
  let savedCallLog;
  try {
    savedCallLog = (JSON.parse(savedCallLogText) ?? []).filter(
      (a, b) => b < 200
    ); // We only want first 200 records
  } catch (e) {
    savedCallLog = [];
  }

  const [clientName, setClientName] = React.useState(DEF_CLIENT_NAME);
  const [ccpReady, setCCPReady] = React.useState(false);
  const [loginVisible, setLoginVisible] = React.useState(false);
  const [iframeDead, setIframeDead] = React.useState(false);
  const [waitingForLogin, setWaitingForLogin] = React.useState(false);
  const [waitingForInitialLoad, setWaitingForInitialLoad] =
    React.useState(true);
  const [callLog, setCallLog] = React.useState(savedCallLog);
  const [activeContactID, setActiveContactID] = React.useState(null);
  const [modalMessage, setModalMessage] = React.useState(null);

  // Onload
  React.useEffect(() => {
    const container = document.getElementById("ccp");
    const iframe = document.querySelector("#ccp iframe");
    if (iframe) {
      iframe.remove();
    }

    const instanceURL = ccpURL;
    window.connect.core.initCCP(container, {
      ccpUrl: instanceURL,
      loginPopup: false,
      loginPopupAutoClose: false,
      pageOptions: {
        enableAudioDeviceSettings: true,
        enablePhoneTypeSettings: false,
      },
      softphone: {
        allowFramedSoftphone: true,
      },
    });
    if (DEBUG_MODE) {
      window.connect.core.getEventBus().subscribeAll((a, b, c) => {
        console.log({ a, b, c });
      });
    }

    window.connect.core
      .getEventBus()
      .subscribe(window.connect.WebSocketEvents.CONNECTION_GAIN, () => {
        console.log("CONNECTION_GAIN");
        setCCPReady(true);
      });

    window.connect.core.onIframeRetriesExhausted(() => {
      console.log("We have run out of retries to reload the CCP Iframe");
      setIframeDead(true);
      if (window.connect.core.loginWindow?.window) {
        window.connect.core.loginWindow.close();
      }
      window.location.reload();
    });

    window.connect.core.onInitialized(function () {
      setCCPReady(true);
    });
    window.connect.core.onSoftphoneSessionInit(function ({ connectionId }) {
      setCCPReady(true);
    });
    window.connect.core.onAuthFail(function () {
      setCCPReady(false);
    });
    window.connect.core
      .getEventBus()
      .subscribe(window.connect.EventType.TERMINATED, () => {
        setCCPReady(false);
      });

    window.connect.core.onAccessDenied(function () {
      console.log("Access denied");
    });

    window.connect.agent(() => {
      console.log("Agent!");
    });

    window.connect.core
      .getEventBus()
      .subscribe(window.connect.EventType.ACK_TIMEOUT, () => {
        setWaitingForInitialLoad(false);
        if (ccpReady) {
          setCCPReady(false);
        } else {
          setLoginVisible(true);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerLogin = () => {
    if (iframeDead) {
      if (window.connect.core.loginWindow?.window) {
        window.connect.core.loginWindow.close();
        window.connect.core.loginWindow = null;
      }
      window.location.reload();
      return;
    }

    setWaitingForLogin(true);
    window.connect.core
      .getPopupManager()
      .clear(window.connect.MasterTopics.LOGIN_POPUP);
    window.connect.core.iframeRefreshAttempt = 0;

    window.connect.core.loginWindow = window.connect.core
      .getPopupManager()
      .open(ccpURL, window.connect.MasterTopics.LOGIN_POPUP, {
        autoClose: false,
        height: 600,
        width: 400,
        top: 0,
        left: 0,
      });
  };

  const triggerCall = (number) => {
    const endpoint = window.connect.Endpoint.byPhoneNumber(number);
    const agent = new window.connect.Agent();
    const routingProfile = agent.getRoutingProfile();
    const outboundQueueARN = routingProfile.defaultOutboundQueue.queueARN;
    agent.connect(endpoint, {
      queueARN: outboundQueueARN,
      success: () => {},
      failure: (err) => {
        const oErr = JSON.parse(err);
        setModalMessage({
          title: "Failed to connect outbound call.",
          message: oErr.message,
          error: err,
        });
      },
    });
  };

  const triggerCopyNumber = async (number) => {
    await window.navigator.clipboard.writeText(number.replace(/\+/g, ""));
  };

  const getDateText = (date) => {
    if (date.getDay() === new Date().getDay()) return "Today";
    if (
      date.getDay() === new Date().getDay() - 1 ||
      (date.getDay() === 6 && new Date().getDay() === 0)
    )
      return "Yesterday";
    return date.toDateString();
  };

  React.useEffect(() => {
    window.localStorage.setItem("callLog", JSON.stringify(callLog));
  }, [callLog]);

  React.useEffect(() => {
    console.log(`ccpready changed ${ccpReady}`);
    if (!ccpReady) {
      if (!waitingForInitialLoad) {
        setLoginVisible(true);
      }
      return;
    }

    if (window.connect.core.loginWindow) {
      window.connect.core.loginWindow.close();
      window.connect.core.loginWindow = null;
    }

    setWaitingForInitialLoad(false);
    setWaitingForLogin(false);
    setLoginVisible(false);

    window.connect.contact(function (contact) {
      let clientName = contact.getAttributes()?.CustomerName?.value;
      if (!contact.isInbound) clientName = DIRECTIONS.OUTBOUND;

      setClientName(clientName);

      contact.onConnected((contact) => {
        const contactID = contact.getContactId();
        setActiveContactID(contactID);
        const logItem = {
          id: contactID,
          clientName: clientName ?? "",
          direction: contact.isInbound()
            ? DIRECTIONS.INBOUND
            : DIRECTIONS.OUTBOUND,
          number: contact.getInitialConnection().getEndpoint().phoneNumber,
          start: new Date().toISOString(),
        };
        setCallLog((callLog) => [logItem, ...callLog]);
      });
      contact.onACW((contact) => {
        setCallLog((callLog) => {
          const logItem = callLog.find((i) => i.id === contact.getContactId());
          if (logItem) logItem.end = new Date().toISOString();
          return callLog;
        });
        setActiveContactID(null);
      });
      contact.onDestroy((contact) => {
        setClientName(DEF_CLIENT_NAME);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccpReady]);

  return (
    <div className="outer-app-wrapper">
      <div className="App">
        <header>
          <h1>BenManage Call Center</h1>
        </header>
        <main>
          <div className="info">
            <div id="callerID" className="caller-id">
              <b>Current caller:</b>&nbsp;
              {clientName}
            </div>

            <div className="call-history">
              <h2>Call History</h2>
              <ul>
                {callLog.map((logItem, key) => {
                  const startDate = new Date(logItem.start);

                  return (
                    <li
                      className={`call-log-item${
                        logItem.id === activeContactID ? " current" : ""
                      }`}
                      key={key}
                    >
                      <div className="icon">
                        {logItem.direction === DIRECTIONS.INBOUND ? (
                          <CallReceived className="inbound" />
                        ) : (
                          <CallMade className="outbound" />
                        )}
                      </div>
                      <div className="details">
                        <div className="header">
                          <span className="caller-id">
                            {logItem.clientName &&
                            logItem.clientName.trim().length > 0
                              ? logItem.clientName
                              : logItem.direction}
                            :
                          </span>
                          &nbsp;
                          {logItem.number.replace(
                            /\+1([0-9]{3})([0-9]{3})([0-9]{4})/,
                            "($1) $2-$3"
                          )}
                        </div>
                        <div className="time">
                          {getDateText(startDate)}
                          &nbsp;-&nbsp;
                          {startDate.toLocaleTimeString()}
                        </div>
                      </div>
                      <div className="actions">
                        <span
                        title={`Call ${logItem.number.replace(
                          /\+1([0-9]{3})([0-9]{3})([0-9]{4})/,
                          "($1) $2-$3"
                        )}`}
                          className="call"
                          onClick={() => triggerCall(logItem.number)}
                        >
                          <Phone />
                        </span>
                        <span
                          title="Copy the number to the clipboard"
                          className="copy"
                          onClick={() => triggerCopyNumber(logItem.number)}
                        >
                          <ContentCopy />
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="ccp-outer-wrapper">
            <div
              className={`ccp-inner-wrapper ${
                ccpReady ? "ccp-ready" : "ccp-error"
              }`}
            >
              <div
                style={{
                  display:
                    !ccpReady && !loginVisible && !waitingForLogin
                      ? "initial"
                      : "none",
                }}
              >
                Initializing softphone...
              </div>
              <div
                style={{
                  display: !ccpReady ? "initial" : "none",
                }}
              >
                {iframeDead
                  ? "Failed to connect. Please refresh the page and try again."
                  : waitingForLogin
                  ? "Waiting for login in the pop-up window. Once you have logged in, the pop-up window should " +
                    "close automatically after a few seconds."
                  : ""}
              </div>

              <button
                onClick={triggerLogin}
                className={loginVisible && !waitingForLogin ? "visible" : ""}
              >
                Login
              </button>
              <div id="ccp"></div>
            </div>
          </div>
        </main>
      </div>
      <dialog className="modal" open={modalMessage != null ? true : false}>
        <div className="modal-container">
          <div className="modal-info">
            <div className="title">{modalMessage?.title}</div>
            <div className="message">{modalMessage?.message}</div>

            <button
              onClick={() => {
                setModalMessage(null);
              }}
            >
              Okay
            </button>

            <details>
              <summary>Click for technical details</summary>
              <div>{modalMessage?.error}</div>
            </details>
          </div>
        </div>
      </dialog>
    </div>
  );
}

export default App;
